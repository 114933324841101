import { graphql } from "gatsby"
import { PageTemplate } from "../templates/Page/PageTemplate"
import { PageTemplateProps } from "../templates/Page/PageTemplateProps"
export default function NotFoundFactory(props: PageTemplateProps) {
    return new PageTemplate(props)
}

export const pageQuery = graphql`
    query NotFoundById($id: String = "page-notfound0", $layout: String = "layout-en0000000") {
        page: allMarkdownRemark(filter: { frontmatter: { id: { eq: $id }, type: { eq: "page" } } }) {
            nodes {
                ...PageFragment
            }
        }
        layout: allMarkdownRemark(filter: { frontmatter: { id: { eq: $layout }, type: { eq: "layout" } } }) {
            nodes {
                ...NewLayoutFragment
            }
        }
    }
`
